import React, { useState } from "react"
import { navigate } from "gatsby"

const ProductDemoLogin = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  // Slack webhook URL from environment variables
  const WEBHOOK_URL = process.env.GATSBY_SLACK_WEBHOOK
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#F8F8F8",
  }

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    maxWidth: "1145px",
    alignItems: "center",
  }

  const inputStyle = {
    width: "100%",
    padding: "10px",
    height: "50px",
    backgroundColor: "#E9EAEB",
    marginTop: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "16px",
  }

  const errorStyle = {
    color: "red",
    fontSize: "14px",
    marginTop: "5px",
  }

  const successStyle = {
    color: "green",
    fontSize: "16px",
    marginTop: "10px",
  }

  const buttonStyle = {
    width: "120px",
    height: "42px",
    backgroundColor: "#553193",
    opacity: isLoading ? "0.5" : "0.8",
    color: "white",
    lineHeight: "20px",
    borderRadius: "30px",
    fontSize: "16px",
    cursor: isLoading ? "not-allowed" : "pointer",
    alignSelf: "end",
    marginTop: "20px",
    border: "none",
  }

  // Function to send message using Slack webhook
  const sendToSlackWebhook = async userEmail => {
    try {
      const formattedTime = new Date().toLocaleString()

      const payload = {
        text: `*New Product Demo Login*\n• Email: ${userEmail}\n• Time: ${formattedTime}`,
      }

      const response = await fetch(WEBHOOK_URL, {
        method: "POST",
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        const errorText = await response.text()
        console.error("Webhook Error:", errorText)
        return false
      }

      return true
    } catch (error) {
      console.error("Error sending to Slack webhook:", error)
      return false
    }
  }

  const handleLogin = async () => {
    setIsLoading(true)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address")
      setSuccess("")
      setIsLoading(false)
      return
    }

    try {
      // Send email to Slack
      const slackSuccess = await sendToSlackWebhook(email)

      if (!slackSuccess) {
        // If Slack notification failed, still allow login but log the error
        console.warn("Slack notification failed, but proceeding with login")
      }

      setError("")
      setSuccess("Login successful! Redirecting...")

      // Redirect after successful login
      setTimeout(() => {
        navigate(process.env.GATSBY_DEMO_URL)
      }, 2000)
    } catch (err) {
      console.error("Login process error:", err)
      setError("An error occurred during login. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleKeyDown = e => {
    if (e.key === "Enter" && !isLoading) {
      handleLogin()
    }
  }

  return (
    <div style={containerStyle}>
      <div
        style={{ backgroundColor: "#FFFFFF80", height: "100px", width: "100%" }}
      >
        <img
          style={{
            paddingTop: "22px",
            paddingLeft: "61px",
            paddingBottom: "22px",
          }}
          src="/img/Hyfen8_logo_RGB_positive_FA 1.png"
          alt=""
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <div style={cardStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "80px 0px 72px 0px",
              maxWidth: "686px",
            }}
          >
            <h2
              style={{
                fontSize: "48px",
                fontWeight: "300",
                lineHeight: "60px",
                color: "#353435",
              }}
            >
              Product demo
            </h2>
            <p
              style={{
                fontSize: "24px",
                lineHeight: "40px",
                paddingTop: "40px",
                color: "#353435",
              }}
            >
              By logging in, you are agreeing to comply with our{" "}
              <a
                style={{ cursor: "pointer", textDecoration: "underline" }}
                href="https://prod-website-hyfen8-public.s3.ap-southeast-2.amazonaws.com/Hyfen+Hub+Pty+Ltd+Non+Disclosure+Terms.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                non-disclosure agreement
                <img src="/open_in_new.png" alt="" />
              </a>{" "}
              terms.
            </p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                htmlFor="email"
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "30px",
                }}
              >
                Email
                <input
                  id="email"
                  type="email"
                  placeholder="Enter your email address"
                  style={inputStyle}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onKeyDown={handleKeyDown}
                  disabled={isLoading}
                />
              </label>
              {error && <span style={errorStyle}>{error}</span>}
              {success && <span style={successStyle}>{success}</span>}
              <button
                onClick={handleLogin}
                style={buttonStyle}
                disabled={isLoading}
              >
                {isLoading ? "LOADING..." : "LOG IN"}
              </button>
            </div>
          </div>
          <p
            style={{
              paddingTop: "32px",
              fontSize: "14px",
              color: "#666",
              borderTop: "1px solid #00000080",
              fontFamily: "Rubik",
            }}
          >
            We treat your data with the utmost care, integrity and
            confidentiality.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ProductDemoLogin
